.form input {
  padding: 8px;
  max-width: 180px;
}

.form label {
  display: block;
  margin-bottom: 5px;
}

.form button {
  margin-left: 10px;
  padding: 8px 15px;
  background-color: #000000;
  border: 2px solid #000000;
  color: #fff;
}

.form button:hover {
  background-color: rgb(62, 62, 62);
  color: #fff;
  cursor: pointer;
}
