.Nav {
  text-align: center;
}
.Nav button {
    padding: 10px;
    background-color: #000000;
    border: 1px solid #000000;
    color: #ffffff;
    margin: 8px;
    width: 112px;
}
.Nav button:hover {
    background-color: rgb(62, 62, 62);
    color: #fff;
    cursor: pointer;
  }

.Nav button:disabled {
    background-color: #ddd;
    border: 1px solid #bbb;
    color: #bbb;
   
}
