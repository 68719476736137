/* By default we target mobile devices always */
.container {
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  margin: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container {
    max-width: 960px;
    padding-top: 4em;
  }
}