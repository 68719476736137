.gallery {
  display: flex;
}

.gallery ul {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
}

.gallery ul li {
  overflow: hidden;
  margin-bottom: 15px;
  flex: 100%
}

.gallery ul img {
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .gallery ul li {
    flex: 30% 1;
    padding: 0 10px;
    max-height: 200px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .gallery ul li {
    flex: 20% 1;
    padding: 0 10px;
    max-height: 200px;
  }
}
